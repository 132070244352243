<template>
  <v-card>
    <v-card-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="shrink"
        label="Wyszukaj"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:item.minProductPrice="{item}">
        {{ item.minProductPrice | currency }}
      </template>
      <template v-slot:item.maxProductPrice="{item}">
        {{ item.maxProductPrice | currency }}
      </template>
      <template v-slot:item.bindingPrice="{item}">
        {{ item.bindingPrice | currency(2, 4) }}
      </template>
      <template v-slot:item.actions="{item}">
        <EditPricingModal @formSubmitted="updatePrice" :item="item" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import EditPricingModal from '@/components/Modals/EditPricingModal'

const PricingRepository = RepositoryFactory.get('pricing')

export default {
  name: 'Pricing',
  components: {EditPricingModal},
  mixins: [paginationMixin],
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {text: 'Wartość produktu od', value: 'minProductPrice'},
        {text: 'Wartość produktu do', value: 'maxProductPrice'},
        {text: 'Max. stawka', value: 'bindingPrice'},
        {text: 'Edycja', value: 'actions', sortable: false, width: '7%', align: 'center'},
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchPricingList', 'showNotification']),
    async fetchItems() {
      const {search} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const query = this.$_paginationMixin_createQuery({
        ...this.$data.$_paginationMixin_options,
        search,
      })
      this.$data.$_paginationMixin_totalCount = await this.fetchPricingList({
        ...query,
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
    async updatePrice(form) {
      try {
        await PricingRepository.updatePrice(form)
        await this.fetchItems()
        this.showNotification({
          message: 'NOTY_PRICING_UPDATED',
          type: 'success',
        })
      } catch (e) {
        this.showNotification({
          message: e.response.data,
          type: 'error',
        })
      }
    },
  },
  computed: {
    ...mapState({
      items: state => state.pricing.pricingList,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
      }
    },
  },
}
</script>

<style></style>
