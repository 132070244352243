<template>
  <v-dialog :max-width="370" v-model="show">
    <template v-slot:activator="{on}">
      <v-icon v-on="on" size="16" @click="open">mdi-pencil</v-icon>
    </template>
    <v-form :valid="valid" @submit.prevent="save" @keyup.enter="save" ref="form" lazy-validation>
      <v-card>
        <v-card-title class="py-6">
          <h6 class="text-h6 mx-auto">Edycja maksymalnej stawki</h6>
        </v-card-title>
        <v-card-text>
          <v-text-field
            disabled
            dense
            outlined
            label="Wartość produktu w przedziale"
            :value="priceRange"
          >
            <template v-slot:append>
              zł
            </template>
          </v-text-field>
          <v-text-field
            :rules="[bindingPriceRule, $rules.greaterThan(0)]"
            v-model="form.bindingPrice"
            dense
            outlined
            type="number"
            label="Maksymalna stawka"
          >
            <template v-slot:append>
              zł
            </template>
          </v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn elevation="0" @click="close">Anuluj</v-btn>
          <v-btn color="primary" type="submit" :loading="pending">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditPricingModal',
  props: {
    item: {
      type: Object,
      required: true,
    },
    pending: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      form: {
        minProductPrice: null,
        maxProductPrice: null,
        bindingPrice: null,
      },
      show: false,
      valid: true,
    }
  },
  watch: {
    show() {
      this.setForm()
    },
  },
  computed: {
    bindingPriceRule() {
      const {bindingPrice, maxProductPrice} = this.form
      return () => bindingPrice <= maxProductPrice || 'Wartość nie może być większa od przedziału'
    },
    priceRange() {
      const {minProductPrice, maxProductPrice} = this.form

      return `${minProductPrice}-${maxProductPrice}`
    },
  },
  methods: {
    setForm() {
      const {minProductPrice, maxProductPrice, bindingPrice} = this.item
      this.form = {
        minProductPrice,
        maxProductPrice,
        bindingPrice,
      }
    },
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    formatNumber(v) {
      return parseFloat(v).toFixed(4)
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('formSubmitted', {
          biddingPriceId: this.item.id,
          biddingPrice: this.formatNumber(this.form.bindingPrice),
        })
        this.close()
      }
    },
  },
}
</script>

<style scoped></style>
